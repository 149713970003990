const delivery = [
  {
    path: '/coupons',
    name: 'coupons.list',
    component: () => import('@/views/coupons/CouponList.vue'),
    meta: {
      pageTitle: 'Listar',
      roles: [1, 2, 5, 6],
      breadcrumb: [
        {
          text: 'Cupons',
          active: true,
        },
      ],
    },
  },
]

export default delivery
